import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Section from '../components/shared/section';
import ContentBlock from '../components/shared/content-block';
import { setPage, openModal } from '../store/app';

const PrivacyPolicy = ({ setPage }) => {
  useEffect(() => {
    setPage('privacy-policy');
  }, [setPage]);

  return (
    <>
      <Section className="bg-grey-light-new p-10">
        <ContentBlock className="new-content-block text-justify terms-content">
          <h1 style={{ fontSize: 24, textAlign: 'center' }}>POLITYKA PRYWATNOŚCI</h1>
          <h1 style={{ fontSize: 24, textAlign: 'center', marginBottom: 12 }}>OCHRONA DANYCH OSOBOWYCH</h1>
          <hr style={{ margin: '24px 0', height: 1, background: '#292929' }} />
          <p>
            W związku z wejściem w życie w dniu 25 maja 2018 roku Rozporządzenia Ogólnego o Ochronie Danych Osobowych
            przyjętego dnia 27 kwietnia 2016 r. przez Parlament Europejski, które dotyczy ochrony osób fizycznych w
            związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych (dalej zwane
            „RODO”), informuje, iż przysługują Pani/Panu określone poniżej prawa związane z przetwarzaniem Pani/Pana
            danych osobowych przez Vasco Electronics Góralski Group S.K.A. z siedzibą w Krakowie przy al. 29 Listopada
            20, 31-401 Kraków.
          </p>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>1. Administrator danych osobowych</h2>
          <p>
            Administratorem Pani/Pana danych osobowych jest Vasco Electronics Góralski Group S.K.A. z siedzibą w
            Krakowie przy al. 29 Listopada 20, 31-401 Kraków.
          </p>
          Może się Pani/Pan z nami skontaktować:
          <ul>
            <li>listownie na adres: al. 29 Listopada 20, 31-401 Kraków.</li>
            <li>
              przez e-mail:
              <a href="mailto:gdpr@vasco-electronics.com" className="link">
                {` gdpr@vasco-electronics.com`}
              </a>
              .
            </li>
          </ul>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>2. Jakie dane przetwarzamy?</h2>
          <p>Na potrzeby, o których piszemy poniżej zbieramy i przetwarzamy następujące kategorie danych osobowych:</p>
          <ul>
            <li>
              <b>Dane identyfikacyjne.</b> Dane takie jak: Imię, Nazwisko, adres korespondencyjny, adres rozliczeniowy,
              nazwa prowadzonej działalności, NIP, adres stadniny mogą być konieczne do założenia konta użytkownika w
              naszej aplikacji oraz prawidłowego świadczenia usługi.
            </li>
            <li>
              <b>Dane kontaktowe.</b> Adres e-mail jest niezbędny do założenia konta w naszej aplikacji oraz prowadzenia
              korespondencji w zakresie utrzymania świadczonej usługi. Adres e-mail może być wykorzystany także do
              udostępnienia niektórych funkcjonalności naszej aplikacji.
            </li>
            <li>
              <b>Dane dotyczące sprzedaży.</b> W celu realizacji naszych zobowiązań wobec naszych klientów oraz w
              związku z obowiązkami prawnymi możemy przetwarzać dane dotyczące sprzedaży takie jak numer zamówienia,
              dane dotyczące płatności itp.
            </li>
            <li>
              <b>Informacje dotyczące korzystania z aplikacji.</b> Dane takie jak: czas dostępu, liczba przypadków
              uzyskania dostępu, adres IP i informacje o zdarzeniach (takich jak błędy, zawieszenia, ponowne
              uruchomienia i aktualizacje do nowych wersji) i inne dane diagnostyczne, techniczne, informacje o błędach
              i użytkowaniu, np. godzina i czas korzystania z usług, wyszukiwane terminy wprowadzone przez Użytkownika
              na urządzeniu oraz wszelkie informacje przechowywane w plikach cookie umieszczonych na urządzeniu
              Użytkownika.
            </li>
            <li>
              <b>Cookies.</b> Na naszej stronie internetowej korzystamy z Cookies. Są to dane informatyczne, najczęściej
              pliki tekstowe, które są przechowywane na urządzeniu końcowym. Zazwyczaj zawierają nazwę strony
              internetowej, z której pochodzą, czas przechowywania oraz numer. Więcej informacji na temat plików cookies
              dostępnych jest pod adresem https://pl.wikipedia.org/wiki/HTTP_cookie lub w sekcjach „Pomoc” w menu
              przeglądarek internetowych.
            </li>
          </ul>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>
            3. Pani/Pana dane osobowe będą przetwarzane w celu:
          </h2>
          <ul>
            <li>
              <b>przeprowadzenie procesu sprzedaży</b> — dane identyfikacyjne, dane kontaktowe - na podstawie art. 6
              ust. 1 lit. b RODO (niezbędność do zawarcia i/lub wykonania umowy);
            </li>
            <li>
              <b>utworzenie i prowadzenie konta klienta</b> — dane identyfikacyjne, dane kontaktowe, dane sprzedaży - na
              podstawie art. 6 ust. 1 lit. a RODO (zgoda) oraz na podstawie art. 6 ust. 1 lit. b RODO (niezbędność do
              zawarcia i/lub wykonania umowy w zakresie prowadzenia konta użytkownika);
            </li>
            <li>
              <b>rozpatrzenia reklamacji</b> — dane identyfikacyjne, dane kontaktowe, dane sprzedaży - na podstawie art.
              6 ust. 1 lit. b RODO (niezbędność do zawarcia i/lub wykonania umowy);
            </li>
            <li>
              <b>kontakt w sprawach związanych z realizacją umowy lub ofertą Administratora</b> — dane identyfikacyjne,
              dane kontaktowe, dane dotyczące sprzedaży - na podstawie art. 6 ust. 1 lit. b RODO (niezbędność do
              zawarcia i/lub wykonania umowy);
            </li>
            <li>
              <b>wystawienia faktury i spełnienia innych obowiązków wynikających z przepisów prawa podatkowego</b> —
              dane identyfikacyjne, dane kontaktowe, dane dotyczące sprzedaży - na podstawie art. 6 ust. 1 lit. c RODO
              (niezbędność do wypełnienia obowiązku prawnego przez Spółkę);
            </li>
            <li>
              <b>przechowywania nieopłaconych zapytań</b> - dane dotyczące sprzedaży — na podstawie art. 6 ust. 1 lit. f
              RODO (prawnie uzasadniony interes Administratora Danych Osobowych); prawnie uzasadnionym interesem Spółki
              jest prowadzenie statystyk niezrealizowanych zamówień w celu ulepszania jakości świadczonych usług.
            </li>
            <li>
              <b>marketing bezpośredni</b> — dane kontaktowe - na podstawie art. 6 ust. 1 lit. a RODO (zgoda);
            </li>
            <li>
              <b>ustalenia, dochodzenia lub obrony przed roszczeniami</b> - dane identyfikacyjne, dane kontaktowe, dane
              sprzedaży — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych
              Osobowych); prawnie uzasadnionym interesem Spółki jest ochrona interesu majątkowego Spółki oraz
              użytkowników;
            </li>
            <li>
              <b>
                archiwalnym i dowodowym, na potrzeby zabezpieczenia informacji, które mogą służyć wykazywaniu faktów
              </b>
              - dane identyfikacyjne, dane kontaktowe, dane sprzedaży — na podstawie art. 6 ust. 1 lit. f RODO (prawnie
              uzasadniony interes Administratora Danych Osobowych); prawnie uzasadnionym interesem Spółki jest
              posiadanie informacji potrzebnych np. organom państwowym;
            </li>
            <li>
              <b>
                analitycznym, polegającym między innymi na analizie danych zbieranych automatycznie przy korzystaniu ze
                strony internetowej, w tym cookies Informacje dotyczące przeglądania witryny
              </b>
              - Informacje dotyczące korzystania z aplikacji, cookies — na podstawie art. 6 ust. 1 lit. a (zgoda) oraz
              art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych); prawnie
              uzasadnionym interesem Spółki jest poznanie aktywności użytkowników w celu optymalizacji swoich usług;
            </li>
            <li>
              <b>administrowania stroną internetową i aplikacją</b> - Informacje dotyczące korzystania z aplikacji,
              cookies — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych
              Osobowych); prawnie uzasadnionym interesem Spółki jest sprawne zarządzanie serwisem;
            </li>
          </ul>
          <p>
            W każdej chwili może Pan/Pani wycofać udzieloną zgodę, przy czym wycofanie zgody pozostaje bez wpływu na
            zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
          </p>
          <p>Podanie wszystkich danych osobowych jest dobrowolne, jednak:</p>
          <ul>
            <li>
              podanie takich danych, jak imię i nazwisko oraz adres e-mail jest konieczne do zawarcia umowy (bez tych
              danych wykonanie usługi będzie niemożliwe);
            </li>
            <li>
              podanie takich danych, jak imię i nazwisko, adres e-mail jest konieczne do rozpatrzenia reklamacji, a w
              przypadku zwrotu pieniędzy — także podanie adresu i numeru rachunku bankowego (bez tych danych
              rozpatrzenie reklamacji lub zwrotu nie będzie możliwe);
            </li>
            <li>
              podanie takich danych, jak imię i nazwisko lub firma, adres siedziby, numer NIP jest konieczne do
              wystawienia faktury za usługi i wynika to z przepisów prawa (bez tych danych wystawienie faktury nie
              będzie możliwe);
            </li>
          </ul>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>4. Odbiorcy danych</h2>
          <p>
            Pana/Pani dane osobowe mogą zostać ujawnione naszym doradcom, prawnikom, firmom obsługującym płatności,
            firmie hostingowej, firmie księgowej, firmie ubezpieczeniowej, firmie odpowiedzialnej za system ERP, firmie
            odpowiedzialnej za zarządzanie obsługą klienta, a także podmiotom wspomagających nas w zbieraniu opinii o
            naszych produktach i usługach. Oprócz tego możemy zostać zobowiązani np. na podstawie przepisu prawa do
            udostępnienia Pana/Pani danych osobowych podmiotom prywatnym i publicznym.
          </p>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>
            5. Przekazywanie danych osobowych do państwa trzeciego lub organizacji międzynarodowej
          </h2>
          <p>
            Pani/Pana dane osobowe mogą być przez Administratora Danych Osobowych przekazywane do państw spoza
            Europejskiego Obszaru Gospodarczego (obejmującego Unię Europejską, Norwegię, Liechtenstein i Islandię), tj.
            do podmiotów takich jak: Google LLC, Facebook Inc.
          </p>
          <p>
            Przekazywanie Pani/Pana danych osobowych do podmiotów spoza EOG odbywa się na podstawie wyrażonej zgody lub
            w zakresie niezbędnym do wykonania zawartej umowy.
          </p>
          <p>
            Informujemy, że w przypadku przekazywania Pani/Pana danych osobowych do podmiotów spoza EOG istnieje ryzyko
            braku zapewnienia równorzędnego do wynikającego z RODO stopnia ochrony Państwa danych osobowych przez kraje,
            w których siedziby mają te podmioty.
          </p>
          <p>
            Niemniej jednak, w celu zapewnienia jak najwyższego stopnia ochrony Państwa danych osobowych, w relacjach z
            podmiotami spoza EOG stosujemy również standardowe klauzule umowne dotyczące ochrony danych osobowych,
            zatwierdzone przez Komisję Europejską.
          </p>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>6. Okres przechowywania danych osobowych</h2>
          <p>Pana/Pani dane osobowe będą przechowywane:</p>
          <ul style={{ listStyle: 'none' }}>
            <li>
              <b>przez okres trwania umowy</b> — w przypadku danych osobowych przetwarzanych w celu zawarcia i wykonania
              umowy;
            </li>
            <li>
              <b>przez okres 3 lat lub 6 lat + 1 rok</b> — w odniesieniu do danych osobowych przetwarzanych w celu
              ustalenia, dochodzenia i obsługi roszczeń (długość okresu zależy od tego, czy obie strony są
              przedsiębiorcami, czy też nie);
            </li>
            <li>
              <b>przez okres 6 miesięcy</b> — w odniesieniu do danych osobowych, które zostały zebrane przy
              przedstawieniu oferty i wycenie usługi, a jednocześnie nie doszło do niezwłocznego zawarcia umowy, tj.
              przetwarzanych w celu ewentualnego zawarcia umowy;
            </li>
            <li>
              <b> przez okres 5 lat</b> — w odniesieniu do danych osobowych przetwarzanych w celu wywiązania się z
              obowiązków podatkowych;
            </li>
            <li>
              <b>do czasu cofnięcia zgody lub osiągnięcia celu przetwarzania, jednak nie dłużej niż przez 5 lat</b> — w
              odniesieniu do danych osobowych przetwarzanych na podstawie zgody;
            </li>
            <li>
              <b>
                do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu przetwarzania, jednak nie dłużej niż
                przez 5 lat
              </b>{' '}
              — w odniesieniu do danych osobowych przetwarzanych na podstawie prawnie uzasadnionego interesu
              Administratora Danych Osobowych lub do celów marketingowych;
            </li>
            <li>
              <b>do czasu zdezaktualizowania się lub utraty przydatności, jednak nie dłużej niż przez 3 lata</b> — w
              odniesieniu do danych osobowych przetwarzanych głównie do celów analitycznych, wykorzystania cookies i
              administrowania stroną internetową.
            </li>
          </ul>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>
            7. Informacje o przysługujących Pani/Panu prawach w związku z przetwarzaniem danych osobowych
          </h2>
          <p>
            Przysługuje Pani/Panu prawo: dostępu do treści danych, prawo do żądania sprostowania danych, ich usunięcia,
            ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu względem
            przetwarzania danych.
          </p>
          <p>Wniosek o realizację przysługujących Pani/Panu praw może Pani/Pan zgłosić:</p>
          <ul style={{ listStyle: 'none' }}>
            <li>
              <b>a. na adres email</b>
              {` - `}
              <a href="mailto:gdpr@vasco-electronics.com" className="link">
                gdpr@vasco-electronics.com
              </a>
            </li>
            <li>
              <b>b. listownie na adres</b> – al. 29 Listopada 20, 31-401 Kraków
            </li>
          </ul>
          <p>We wniosku powinna Pani/Pan podać dane, które pozwolą nam jednoznacznie zidentyfikować Panią/Pana.</p>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>
            8. Informacje o prawie wniesienia skargi do organu nadzorczego
          </h2>
          <p>
            Przysługuje również Pani/Panu prawo do wniesienia skargi do organu nadzorczego zajmującego się ochroną
            danych osobowych- do Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna Pani/Pan, że przetwarzanie Pani/Pana
            danych osobowych niezgodnie z prawem.
          </p>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>9. Środki bezpieczeństwa</h2>
          <p>Naszym celem jest zapewnienia poziomu ochrony wynikającego z obowiązujących przepisów prawa:</p>
          <ul>
            <li>
              Rozporządzenia Parlamentu Europejskiego i Rady 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
              fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz
              uchylenia dyrektywy 95/46/WE (RODO),
            </li>
            <li>Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną,</li>
            <li>Ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne.</li>
          </ul>
          <p>
            Korzystanie z naszego serwisu jest realizowane za pomocą protokołu SSL, który jest specjalnym standardem
            przesyłania danych w zakresie ochrony transmisji danych, ponieważ transmisja za pomocą tego protokołu jest
            kodowana.
          </p>
          <p>
            Dokładamy wszelkich możliwych starań, aby zabezpieczyć Pani/Pana dane osobowe i ochronić je przed
            działaniami osób trzecich. Stosujemy wszelkie niezbędne zabezpieczenia serwerów, połączeń i strony
            internetowej w celu ochrony danych, w szczególności szyfrowanie SSL i TLS. Wszystkie połączenia związane z
            wykonywaniem płatności przez naszych klientów będą następować za pośrednictwem bezpiecznego szyfrowanego
            połączenia. Stosujemy również niezbędne środki organizacyjne takie jak szkolenia personelu, hierarchizacja
            dostępu do baz danych, autoryzacja dostępu do pomieszczeń, monitoring oraz zabezpieczenia fizyczne miejsc
            gdzie przechowywane są dane osobowe.
          </p>
          <p>
            Informujemy, że podjęte przez nas działania mogą okazać się jednak niewystarczające, jeżeli nasi klienci
            sami nie zachowają zasad bezpieczeństwa. W szczególności każdy użytkownik powinien zachować w poufności
            login i hasło do swojego konta na stronie internetowej i nie udostępniać ich osobom trzecim. Informujemy, że
            nigdy nie zwracamy się do naszych klientów o podanie danych do logowania.
          </p>
        </ContentBlock>
      </Section>
    </>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
    openModal: modalName => dispatch(openModal(modalName)),
  })
)(PrivacyPolicy);
